import React, { useEffect, useState, useRef } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, NavLink } from 'react-router-dom';
import '../assets/css/wheel.css';
import { Wheel } from 'react-custom-roulette';

// images
import TienXia from '../assets/img/17.png';
import Shisan from '../assets/img/18.png';
import TotalBill from '../assets/img/19.png';
import Edamame from '../assets/img/20.png';
import Guinness from '../assets/img/21.png';
import Tiger from '../assets/img/22.png';
import SpinButton from '../assets/img/spin-btn.png';
// import Pointer from '../assets/img/pointer.png';
// import Nuggets from '../assets/img/nuggets.jpg';
// import Beer from '../assets/img/beer.jpg';
// import Redeem from '../assets/img/redeem.png';

const Vouchers = () => {

  const [mustSpin, setMustSpin] = useState(false);
  const [prizeNumber, setPrizeNumber] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(true);
  const [finalPrize, setFinalPrize] = useState("Click on the spin button to win a prize!");

  const closeModal = () => setIsModalVisible(false);

  const data = [
    { option: '10% off Tien Xia Bukit Jalil', image: { uri: TienXia, offsetY: 100 }, style: { backgroundColor:'#de941d' }, probability: 0.3 },
    { option: '10% off Shisan Bukit Jalil', image: { uri: Shisan, offsetY: 100 }, style: { backgroundColor:'#f5d1b0' }, probability: 0.3 },
    { option: '10% off on total bill', image: { uri: TotalBill, offsetY: 100 }, style: { backgroundColor:'#de941d' }, probability: 0.1 },
    { option: 'Free 1x edamame', image: { uri: Edamame, offsetY: 100 }, style: { backgroundColor:'#f5d1b0' }, probability: 0.15 },
    { option: 'Free 1 set of Guinness', image: { uri: Guinness, offsetY: 100 }, style: { backgroundColor:'#de941d' }, probability: 0.0 },
    { option: 'Free 1 mug of Tiger', image: { uri: Tiger, offsetY: 100 }, style: { backgroundColor:'#f5d1b0' }, probability: 0.15 },
  ];

  const handleSpinClick = () => {
    setFinalPrize("Good luck! ;)")
    if (!mustSpin) {
      const randomValue = Math.random();
      let cumulativeProbability = 0;

      let selectedIndex = 0;
      for (let i = 0; i < data.length; i++) {
        cumulativeProbability += data[i].probability;
        if (randomValue < cumulativeProbability) {
          selectedIndex = i;
          break;
        }
      }
      setPrizeNumber(selectedIndex);
      setMustSpin(true);
    }
  }

	return (
		<section className="section-preset p-0">
      {isModalVisible && (
        <div className="wrapper">
          <div className="container">
            <Wheel
              mustStartSpinning={mustSpin}
              spinDuration={0.5}
              prizeNumber={prizeNumber}
              data={data}
              radiusLineWidth={2}
              radiusLineColor={'de941d'}
              onStopSpinning={() => {
                setMustSpin(false);
                setFinalPrize(data[prizeNumber].option);
              }}
            />
          </div>
          <div id="final-value">
            <button id="spin-btn" onClick={handleSpinClick}><p>Spin</p></button>
            {finalPrize && <p>{finalPrize}</p>}
          </div>
          <button className="close-btn" onClick={closeModal}>×</button>
        </div>
      )}

			<div className="container p-0 mw-100">
				<div className="row m-0">
					<div className="col-12 p-0">
						<div className="w-100 mb-4"></div>
					</div>
					<div className="col-12 p-0">
						<h3 className="brand-blue font-weight-bold text-center">MY VOUCHERS</h3>
						{/* <div className="vouchers">
							<div className="voucher-card">
								<img className="w-100" src={Nuggets} />
								<h4 className="brand-blue">5 PIECES OF NUGGETS</h4>
								<button className="btn">
									<img className="w-100" src={Redeem} />
								</button>
							</div>
							<div className="voucher-card">
								<img className="w-100" src={Beer} />
								<h4 className="brand-blue">1 PINT OF BEER</h4>
								<button className="btn">
									<img className="w-100" src={Redeem} />
								</button>
							</div>
						</div> */}
						<div className="notice">				
							<h3 className="brand-yellow font-weight-bold text-center">
								<i className="fa-sharp fa-solid fa-ticket"></i>	
								<br></br>
								NO VOUCHERS YET
							</h3>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Vouchers;