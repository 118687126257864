import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, NavLink } from 'react-router-dom';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import MiddleEllipsis from "react-middle-ellipsis";

// Swiper
// import { Swiper, SwiperSlide } from 'swiper/react';
// import 'swiper/css';
// import 'swiper/css/navigation';
// import 'swiper/css/pagination';
// import { Navigation, Pagination } from 'swiper/modules';

// images
import Divider from '../assets/img/divider.jpg';
import AccountImg from '../assets/img/profile-img.png';
import EditButton from '../assets/img/edit.png';
import CopyLink from '../assets/img/copy-link.png';

// SwiperCore.use([Pagination]);

import { useAuthContext } from '../hooks/useAuthContext';

const Profile = () => {

	const [copyText, setCopyText] = useState('www.romebar/referralcode.9123891');
	const [copied, setCopied] = useState(false);
	
	const handleCopy = () => {
		setCopied(true);
		setTimeout(() => {
		setCopied(false);
		}, 4000);
	};

	const { loggedInUser } = useAuthContext();

	const [userData, setUserData] = useState(null);
	const fetchUserData = async () => {
		console.log("fetch data...", loggedInUser.user._id);
		const response = await fetch('https://api.rome13.com/user/'+loggedInUser.user._id, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			'Authorization': `Bearer ${loggedInUser.token}`
		}
		});
		const json = await response.json();
		if (response.ok) {
			console.log(json);
			setUserData(json);
		}
	};

	useEffect(() => {
		if (loggedInUser) {
		  fetchUserData();
		}
	}, [loggedInUser]);

	return (
		<section className="section-preset p-0">
			<div className="container p-0 mw-100">
				<div className="row m-0">
					<div className="col-12 p-0">
						<div className="w-100 mb-4">
							{/* <img className="w-100" src={Divider}></img> */}
						</div>
					</div>
					<div className="col-12 p-0">
						<h3 className="brand-yellow font-weight-bold text-center">MY ACCOUNT</h3>
						<div className="w-50 mx-auto my-4 text-center d-flex justify-content-center align-items-center position-relative">
							<img className="w-100" src={AccountImg}></img>
							{/* <button className="btn position-absolute edit-button-pic">
								<img className="w-100" src={EditButton}></img>
							</button> */}
						</div>
						<div className="profile-content w-75 mx-auto my-4 text-center">
							<h4 className="w-75 mx-auto py-3 brand-yellow font-weight-bold profile-info">{userData && userData.fullname}</h4>
							<h5 className="w-75 mx-auto py-3 brand-yellow font-weight-bold profile-info">+{userData && userData.phone}</h5>
							<div className="d-flex justify-content-center align-items-center position-relative">
								<h6 className="w-75 mx-auto py-3 brand-yellow font-weight-bold">*******</h6>
								<button className="btn position-absolute edit-button-pass">
									<img className="w-100" src={EditButton}></img>
								</button>
							</div>
						</div>
						<div className="text-center">
							<h3 className="yellow">REFERRAL CODE</h3>
							<CopyToClipboard text={"https://rome13.com/register?referral="+loggedInUser.user._id} onCopy={handleCopy}>
								<div className="referral-link d-flex justify-content-center align-items-center py-2">
									{/* <MiddleEllipsis><span className="m-0">https://rome13.com/register?referral={userData._id}</span></MiddleEllipsis> */}
									<span className="m-0">{loggedInUser.user._id}</span>
									<img className="ml-2" src={CopyLink}></img>
								</div>
							</CopyToClipboard>
							{copied && <p className="copied-text">Referral copied!</p>}
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Profile;