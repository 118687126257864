import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, NavLink } from 'react-router-dom';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";

import { useLogin } from '../hooks/useLogin';

import Logo from '../assets/img/logo.png';

const Login = () => {

	const {login, error, isLoading} = useLogin();

	const [phone, setPhone] = useState('');
	const [password, setPassword] = useState('');

	const handleSubmit = async (e) => {
		e.preventDefault();
		await login(phone, password);
		// console.log("Login");
	};

	useEffect(() => {

	}, []);

	return (
		<section className="section-preset">
			<div className="container form-page-padding">
				<div className="row form-bg rounded px-3 py-3 my-5">
					<div className="col-12">
						<div className="text-center">
							<img className="register-logo mb-5" src={Logo} alt="logo"></img>
						</div>
						<h4 className="text-white mb-3 text-center font-weight-bold">LOGIN</h4>
						<form className="register-form w-100" onSubmit={handleSubmit}>
							<div className="mb-2">
								<h5 className="brand-primary"><strong>Phone No:</strong></h5>
								<PhoneInput
									inputProps={{
										name: 'phone',
										placeholder: 'Enter Phone Number',
										required: true
									}}
									inputClass="w-100"
									country={"my"}
									enableSearch={false}
									value={phone}
									onChange={(phone) => setPhone(phone)}
								/>
							</div>
							<div className="mb-2">
								<h5 className="brand-primary"><strong>Password:</strong></h5>
								<input
									required
									type="password"
									placeholder='Password'
									name='password'
									className='form-control rounded-0'
									onChange={(e) => setPassword(e.target.value)}
								/>
							</div>
							<button disabled={isLoading} type="submit" className="btn main-button mt-3 w-100">
								<p className="text-white mb-0"><strong>Login</strong></p>
							</button>
							{error && <div className="error">{error}</div>}
						</form>
						<p className="text-gray mt-3 mb-2">Not yet registered?</p>
						<NavLink to="/register" className="btn secondary-button w-100">
							<p className="mb-0"><strong>Register</strong></p>
						</NavLink>
					</div>
				</div>
			</div>
		</section>
	);

};

export default Login;