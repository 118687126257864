import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, NavLink } from 'react-router-dom';
import axios from 'axios';

// hooks
import { useAuthContext } from '../hooks/useAuthContext';

// images
import Divider from '../assets/img/divider.jpg';
import ConfirmBooking from '../assets/img/book-now.png';
import Seating from '../assets/img/seating.jpg';

const Booking = () => {

  const { loggedInUser } = useAuthContext();

	const [selectedDate, setSelectedDate] = useState('');
  const [selectedTable, setSelectedTable] = useState('');
  const [arrivalTime, setArrivalTime] = useState('');  
  const [bookedTables, setBookedTables] = useState([]); 
  const [isFormLocked, setIsFormLocked] = useState(false); 
  const [showNotice, setShowNotice] = useState(''); 
  const [openDropdown, setOpenDropdown] = useState('');
  const currentDate = new Date();

  const tables = [
    'A1', 'A2', 'A3', 'A4', 'A5', 'A6', 'A7', 'A8', 'A9', 'A10', 'A15',
    'VIP1', 'VIP2', 'VIP3', 'VIP4', 'VIP5', 'VIP6', 'VIP7', 'VIP8', 'VIP9'
  ]

  const generateTimeOptions = () => {
    const times = [];
    for (let hour = 17; hour <= 22; hour++) {
      for (let minutes = 0; minutes < 60; minutes += 15) {
        const hourString = hour < 10 ? `0${hour}` : hour;
        const minutesString = minutes < 10 ? `0${minutes}` : minutes;
        times.push(`${hourString}:${minutesString}`);
      }
    }
    return times;
  };

  const handleDateChange = (e) => {
    const selectedDay = new Date(e.target.value).getDay();
    const selectedDate = new Date(e.target.value);
    const isToday = selectedDate.toDateString() === currentDate.toDateString();
    const currentHour = currentDate.getHours();

    if (selectedDay === 0) {
      alert('We are closed on Sundays. Please select another date.');
      setSelectedDate('');
    } else {
      setSelectedDate(e.target.value);

      if (isToday && currentHour >= 17) {
        setIsFormLocked(true);
        setShowNotice('Booking is closed for today. Please try booking before 5 PM.');
      } else {
        setIsFormLocked(false);
        setShowNotice('');
      }
    }
  };

  const isTableBooked = (table) => {
    return bookedTables[selectedDate] && bookedTables[selectedDate].includes(table);
  };

  const handleTableSelect = (table) => {
    if (isTableBooked(table)) {
      alert('This table has already been booked.');
    } else {
      setSelectedTable(table);
    }
  };

  useEffect(() => {
    const currentHour = currentDate.getHours();
    if (currentHour >= 17) {
      setIsFormLocked(true);
      setShowNotice('Booking is closed for today. Please try booking before 5 PM.');
    }
  }, [currentDate]);


  // Telegram bot
  const sendTelegramMessage = async (message) => {
    const botToken = '7981534993:AAG5RZzcuJHfKPs450OtNSAF2NztbVJ8tMQ';
    const chatId = '-1002447334638';

    try {
      await axios.post(`https://api.telegram.org/bot${botToken}/sendMessage`, {
        chat_id: chatId,
        text: message,
      });
    } catch (error) {
      console.error('Error sending message to Telegram:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!selectedDate || !selectedTable || !arrivalTime) {
      alert('Please select a date, table, and time of arrival.');
      return;
    }

    setBookedTables({
      ...bookedTables,
      [selectedDate]: [...(bookedTables[selectedDate] || []), selectedTable],
    });

    const message = 
    `New booking placed by ${loggedInUser.user.fullname} :
    Contact: +${loggedInUser.user.phone}
    Date: ${selectedDate}
    Table: ${selectedTable}
    Arrival Time: ${arrivalTime}`;

    await sendTelegramMessage(message);

    alert(`Booking confirmed for ${selectedTable} on ${selectedDate} with arrival time at ${arrivalTime}`);
    setSelectedDate('');
    setSelectedTable('');
    setArrivalTime('');
  };


	return (
		<section className="section-preset p-0">
			<div className="container p-0 mw-100">
				<div className="row m-0">
					<div className="col-12 p-0">
						<div className="w-100 mb-4">
							{/* <img className="w-100" src={Divider}></img> */}
						</div>
					</div>
					<div className="col-12 p-0">
						<h3 className="brand-green font-weight-bold text-center">BOOKING</h3>
						<div className="w-100 mx-auto">
							<img className="w-100 py-4" src={Seating}></img>
              <div className="w-75 mx-auto mb-5">
                {showNotice && (
                  <div className="alert alert-warning text-center">
                    {showNotice}
                  </div>
                )}
                <form className="booking" onSubmit={handleSubmit}>
                  <input
                    type="date"
                    id="date"
                    value={selectedDate}
                    onChange={handleDateChange}
                    min={new Date().toISOString().split('T')[0]}
                    className="form-control"
                    disabled={isFormLocked}
                  ></input>
                  <CustomSelect
                    options={tables}
                    selectedOption={selectedTable}
                    onSelect={handleTableSelect}
                    isTableBooked={isTableBooked}
                    isDisabled={isFormLocked}
                    placeholder="Select your table"
                    openDropdown={openDropdown}
                    setOpenDropdown={setOpenDropdown} 
                    dropdownId="table"
                  />
                  <CustomSelect
                    options={generateTimeOptions()}
                    selectedOption={arrivalTime}
                    onSelect={(time) => setArrivalTime(time)}
                    isDisabled={isFormLocked}
                    placeholder="Select your ETA"
                    openDropdown={openDropdown}
                    setOpenDropdown={setOpenDropdown} 
                    dropdownId="ETA"
                  />
                  <div className="booking-info">
                    <h6 className="brand-green text-center">Minimum Spend RM500 for VIP tables</h6>
                    <h6 className="brand-green text-center">Booking Cut-off daily 5:00pm</h6>
                  </div>
                  <button type="submit" className="btn mt-3" disabled={isFormLocked} onClick={(e) => {
									  console.log(selectedDate, selectedTable, arrivalTime); }}
                  >
                    <img className="w-50" src={ConfirmBooking} />
                  </button>
                </form>
              </div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

const CustomSelect = ({ 
  options, selectedOption, onSelect, isDisabled, 
  isTableBooked, placeholder,
  openDropdown, setOpenDropdown, dropdownId  }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleSelect = (option) => {
    onSelect(option);
    setIsOpen(false);
    setOpenDropdown('');
  };

  useEffect(() => {
    if (openDropdown === dropdownId) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [openDropdown, dropdownId]);

  return (
    <div className="custom-select-container">
      <div
        className={`custom-select-header ${isDisabled ? 'disabled' : ''}`}
        onClick={() => {
          if (!isDisabled) {
            setOpenDropdown(isOpen ? '' : dropdownId);
          }
        }}
      >
        {selectedOption || placeholder}
        <span className="arrow">{isOpen && !isDisabled ? '▲' : '▼'}</span>
      </div>
      {isOpen && !isDisabled && (
        <ul className="custom-select-dropdown">
        {options.map((option) => {
          const isBooked = dropdownId === 'table' && isTableBooked(option);
          return (
            <li
              key={option}
              className={`custom-select-option ${isBooked ? 'unavailable' : ''}`}
              onClick={() => !isBooked && handleSelect(option)}
              
            >
              <span style={{ textDecoration: isBooked ? 'line-through' : 'none' }}>
                {option}
              </span> 
              <span className="ml-2">{isBooked && '(Booked)'}</span>
            </li>
          );
        })}
      </ul>
      )}
    </div>
  );
};

export default Booking;