// Dependencies
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, NavLink, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import MiddleEllipsis from "react-middle-ellipsis";

// Pages
import RegisterPage from './pages/register';
import LoginPage from './pages/login';
import ProfilePage from './pages/profile';
import BookingPage from './pages/booking';
import VouchersPage from './pages/vouchers';
import LiveBandPage from './pages/live-band';



// hooks
import { useLogout } from './hooks/useLogout';
import { useAuthContext } from './hooks/useAuthContext';

// images
import Logo from './assets/img/logo.png'
import SliderImg from './assets/img/slider-1.jpg'
import Booking from './assets/img/booking.jpg'
import LiveBand from './assets/img/live-band.jpg'
import ProfileImg from './assets/img/profile.jpg'
import Voucher from './assets/img/voucher.jpg'


function AppContent() {

  const { loggedInUser } = useAuthContext();
  const { logout } = useLogout();
  const handleLogout = () => {
    logout();
    window.location.href= "/login";
  }

  const [copied, setCopied] = useState(false);
	const [copyText, setCopyText] = useState("");
	const copyReferral = () => {    
	  setCopied(true);
	  setCopyText("复制成功！");
	  setTimeout(() => setCopied(false), 3000);
	}

	const userRegister = async () => {
    setTimeout(() => fetchUserData(), 2000);
	};

  const [userData, setUserData] = useState(null);
  const fetchUserData = async () => {
    console.log("fetch data...", loggedInUser.user._id);
    const response = await fetch('https://api.rome13.com/user/'+loggedInUser.user._id, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${loggedInUser.token}`
      }
    });
    const json = await response.json();
    if (response.ok) {
      console.log(json);
      setUserData(json);
    }
	};

  const location = useLocation();
  // Switch bg between login/register and other pages
  const getBgClass = (pathname) => {
    return pathname === '/login' || pathname === '/register'
      ? 'mobile-view login-register-bg'
      : 'mobile-view main-bg';
  };
  const isLoginOrRegister = location.pathname === '/login' || location.pathname === '/register';

  useEffect(() => {
    if (loggedInUser) {
      fetchUserData();
    }
  }, [loggedInUser]);

  return (
    <div className="d-flex justify-content-center">
      <div className={getBgClass(location.pathname)}>

          {isLoginOrRegister ? (
            <section className="header">
              <div className="container">
                <div className="row">

                </div>
              </div>
            </section>
            ) : (
              <section className="header">
              <div className="container">
                <div className="row text-center">
                  <NavLink to="/">
                    <img className="w-25 my-4" src={Logo}></img>
                  </NavLink>
                </div>
              </div>
            </section>
          )}

          <Routes>
            <Route
              path="/"
              element={loggedInUser ?
                <section className="section-preset p-0">
                  <div className="container p-0 mw-100">
                    <div className="row m-0">
                      <div className="col-12 p-0">
                        <div className="w-100">
                          <img className="w-100" src={SliderImg}></img>
                        </div>
                      </div>
                      <div className="col-12 action-container">
                        <div className="row">
                          <div className="col-6 setting-button">
                            <NavLink to="/booking">
                              <img className="w-100" src={Booking}></img>
                            </NavLink>
                          </div>
                          <div className="col-6 setting-button">
                            <NavLink to="/live-band">
                              <img className="w-100" src={LiveBand}></img>
                            </NavLink>
                          </div>
                          <div className="col-6 setting-button">
                            <NavLink to="/profile">
                              <img className="w-100" src={ProfileImg}></img>
                            </NavLink>
                          </div>
                          <div className="col-6 setting-button">
                            <NavLink to="/vouchers">
                              <img className="w-100" src={Voucher}></img>
                            </NavLink>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              :
              <Navigate to="/login" />
              }/>
            <Route path="/login" element={!loggedInUser ? <LoginPage/> : <Navigate to="/" />}/>
            <Route path="/register" element={!loggedInUser ? <RegisterPage/> : <Navigate to="/" />}/>
            <Route path="/profile" element={loggedInUser ? <ProfilePage/> : <Navigate to="/login" />}/>
            <Route path="/booking" element={loggedInUser ? <BookingPage/> : <Navigate to="/login" />}/>
            <Route path="/vouchers" element={loggedInUser ? <VouchersPage/> : <Navigate to="/login" />}/>
            <Route path="/live-band" element={loggedInUser ? <LiveBandPage/> : <Navigate to="/login" />}/>
          </Routes>
        {/* </Router> */}
      </div>
    </div>
  );
}

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;