import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, NavLink } from 'react-router-dom';

// Swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Pagination, Navigation, FreeMode } from 'swiper';
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'

// images
import Divider from '../assets/img/divider.jpg';
import Poster from '../assets/img/live-band-poster.png';

SwiperCore.use([Autoplay, Pagination, Navigation, FreeMode]);

const LiveBand = () => {

	return (
		<section className="section-preset p-0">
			<div className="container p-0 mw-100">
				<div className="row m-0">
					<div className="col-12 p-0">
						<div className="w-100 mb-4">
							{/* <img className="w-100" src={Divider}></img> */}
						</div>
					</div>
					<div className="col-12 p-0">
						<h3 className="live-band-title brand-red font-weight-bold text-center">LIVE BAND SCHEDULE</h3>
						<div className="my-4">
							<Swiper
								loop
								grabCursor={true}
								slidesPerView={1}
								pagination={{
									clickable: true,
								}}
								modules={[Pagination]}
								speed={1000}
								// autoplay={{
								// 	delay: 1000,
								// 	disableOnInteraction: false,
								// }}
							>
								<SwiperSlide>
									<div className="schedule-card">
										<div className="schedule-info">
											<h4>MONDAY 30/9/2024</h4>
											<h5>Time: <span>8pm&nbsp;-&nbsp;10pm</span></h5>
											<h5>Band: <span>Rainbow Jazz Man</span></h5>
										</div>
										<a href="#" target="_blank"><img className="w-100" src={Poster} /></a>
									</div>
								</SwiperSlide>
								<SwiperSlide>
									<div className="schedule-card">
										<div className="schedule-info">
											<h4>TUESDAY 1/10/2024</h4>
											<h5>Time: <span>8pm&nbsp;-&nbsp;10pm</span></h5>
											<h5>Band: <span>Some Wannabe DJ</span></h5>
										</div>
										<a href="#" target="_blank"><img className="w-100" src={Poster} /></a>
									</div>
								</SwiperSlide>
								<SwiperSlide>
									<div className="schedule-card">
										<div className="schedule-info">
											<h4>WEDNESDAY 2/10/2024</h4>
											<h5>Time: <span>8pm&nbsp;-&nbsp;10pm</span></h5>
											<h5>Band: <span>DJ Soda</span></h5>
										</div>
										<a href="#" target="_blank"><img className="w-100" src={Poster} /></a>
									</div>
								</SwiperSlide>
								<SwiperSlide>
									<div className="schedule-card">
										<div className="schedule-info">
											<h4>THURSDAY 3/10/2024</h4>
											<h5>Time: <span>8pm&nbsp;-&nbsp;10pm</span></h5>
											<h5>Band: <span>The Emo B</span></h5>
										</div>
										<a href="#" target="_blank"><img className="w-100" src={Poster} /></a>
									</div>
								</SwiperSlide>
								<SwiperSlide>
									<div className="schedule-card">
										<div className="schedule-info">
											<h4>FRIDAY 4/10/2024</h4>
											<h5>Time: <span>8pm&nbsp;-&nbsp;10pm</span></h5>
											<h5>Band: <span>Joe Malone</span></h5>
										</div>
										<a href="#" target="_blank"><img className="w-100" src={Poster} /></a>
									</div>
								</SwiperSlide>
								<SwiperSlide>
									<div className="schedule-card">
										<div className="schedule-info">
											<h4>SATURDAY 5/10/2024</h4>
											<h5>Time: <span>8pm&nbsp;-&nbsp;10pm</span></h5>
											<h5>Band: <span>Black Candy</span></h5>
										</div>
										<a href="#" target="_blank"><img className="w-100" src={Poster} /></a>
									</div>
								</SwiperSlide>
								<SwiperSlide>
									<div className="schedule-card">
										<div className="schedule-info">
											<h4>SUNDAY 6/10/2024</h4>
											<h5>Time: <span>8pm&nbsp;-&nbsp;10pm</span></h5>
											<h5>Band: <span>The Blue Man</span></h5>
										</div>
										<a href="#" target="_blank"><img className="w-100" src={Poster} /></a>
									</div>
								</SwiperSlide>
							</Swiper>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default LiveBand;